import type { RxResponse } from 'features/RxSearch/types'
import apiRoutes from 'service/api-routes'
import { logError } from 'service/logger'

import { protectedApi } from '../service'
import { isRxResponse, sanitizeRxResponse } from '../utils'

const prescriptionApi = {
  previewPrescription: (code: string) =>
    protectedApi.get<RxResponse>(apiRoutes.previewRx(code)),

  searchPrescription: (code: string) =>
    protectedApi.get<RxResponse>(apiRoutes.searchRx(code), {
      transformResponse(data) {
        if (!data || typeof data !== 'string') return data

        try {
          const json = JSON.parse(data)

          if (isRxResponse(json)) {
            return sanitizeRxResponse(json)
          }
        } catch (error) {
          logError(error)
        }

        return data
      },
    }),
}

export default prescriptionApi
