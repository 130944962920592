import { ptBrTransaltion } from 'i18n/locales'
// eslint-disable-next-line no-restricted-imports
import { useTranslation } from 'react-i18next'

import i18n from './i18n'

type TranslateFn = (
  text:
    | DotNestedKeys<typeof ptBrTransaltion>
    | DotNestedKeys<typeof ptBrTransaltion>[],
  options?: Record<string, unknown>
) => string

export function useI18n() {
  const { t: translate } = useTranslation('translation')

  const t: TranslateFn = (text, options = {}) => translate(text, options)

  return {
    t,
  }
}

export const translate: TranslateFn = (text, options = {}) =>
  i18n.t(text, options)
