import { Snackbar } from 'components/ui/Snackbar'
import { HttpServiceProvider } from 'service/Provider'
import { initMonitoring } from 'service/track/elastic'

import { AppRoot } from './router/AppRoot'
import { ThemeProvider } from './styles'

initMonitoring()

export function App() {
  return (
    <HttpServiceProvider>
      <ThemeProvider>
        <Snackbar />
        <AppRoot />
      </ThemeProvider>
    </HttpServiceProvider>
  )
}
