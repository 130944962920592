import { Link } from '@mui/material'
import { useResponsive } from 'hooks/useResponsive'
import { useI18n } from 'i18n'

import { Body } from 'components/ui/Typography'
import { events, track } from 'service/track'

import { version } from '../../../../package.json'

const year = new Date().getFullYear()

type AppVersionProps = { trackReference: 'footer' | 'sidebar' }

export function AppVersion({ trackReference: origin }: AppVersionProps) {
  const isMobile = useResponsive({ size: 600 })
  const { t } = useI18n()

  return (
    <Body variant="xxs" sx={{ marginTop: '1.5rem' }} justifySelf="end">
      {t('layout.footer.copyright', { version, year })}
      {isMobile ? <br /> : ' - '}
      <Link
        target="_blank"
        href={t('layout.footer.termsOfUse.link')}
        title={t('layout.footer.termsOfUse.title')}
        onClick={() => track(events.openedToU({ origin }))}
      >
        {t('layout.footer.termsOfUse.title')}
      </Link>
      {' | '}
      <Link
        target="_blank"
        href={t('layout.footer.privatePolicy.link')}
        title={t('layout.footer.privatePolicy.title')}
        onClick={() => track(events.openedPP({ origin }))}
      >
        {t('layout.footer.privatePolicy.title')}
      </Link>
      {' | '}
      <Link
        target="_blank"
        href={t('layout.footer.about.link')}
        title={t('layout.footer.about.title')}
        onClick={() => track(events.openedPP({ origin }))}
      >
        {t('layout.footer.about.title')}
      </Link>
    </Body>
  )
}
