import Axios, { AxiosInstance } from 'axios'

const API_URL = import.meta.env.VITE_API_URL

export function getAuthToken(): string | undefined {
  const auth = JSON.parse(localStorage.getItem('auth') || '{}')

  return auth?.state?.data?.auth?.token
}

function withAuthorization(instance: AxiosInstance) {
  instance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${getAuthToken()}`

    return config
  })

  return instance
}

export const protectedApi = withAuthorization(
  Axios.create({
    baseURL: API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
)
