import { AxiosError, AxiosResponse } from 'axios'
import { verify } from 'paseto-ts/v4'
import { create } from 'utils'
import { StateCreator } from 'zustand'
import { persist, PersistOptions } from 'zustand/middleware'

import { getApiPublicKey } from 'features/Auth/getPublicApiKey'
import { LoginResponse } from 'features/Auth/types'
import authApi from 'service/api/auth'
import { isBusinessError } from 'service/api/utils'
import { logError } from 'service/logger'

import { LoadingStatus } from '../../constants'

type Persistor = (
  config: StateCreator<AuthStore>,
  options: PersistOptions<AuthStore>
) => StateCreator<AuthStore>

type Pharmacy = {
  companyName: string
  cnpj: string
  phone: string
}

type Pharmacist = {
  name: string
  cpf: string
  crf?: string
  email: string
  password: string
  pharmacy: Pharmacy
}

export type Credentials = Pick<Pharmacist, 'email' | 'password'>

// TODO: move modal control to another store
export type ModalType =
  | 'login'
  | 'recoverPassword'
  | 'changePassword'
  | 'register'
  | 'registerSuccess'

type AuthState = {
  loading: LoadingStatus
  error: string
  isAuthenticated: boolean
  opened: boolean
  data: LoginResponse | null
  refreshSessionTimeout: NodeJS.Timeout | null
  modalVisualization: ModalType
  forcePasswordChange: boolean
}

type AuthActions = {
  changeVisualization: (visualization: ModalType) => void
  close: () => void
  completePasswordChange: () => void
  hydrate: () => void
  open: (visualization?: ModalType) => void
  refreshSession: () => Promise<void>
  scheduleRefreshSession: (time: number) => void
  signIn: (credentails: Credentials, captchaToken: string) => Promise<void>
  signOut: () => void
}

const initialState: AuthState = {
  loading: LoadingStatus.Idle,
  isAuthenticated: false,
  opened: false,
  error: '',
  data: null,
  refreshSessionTimeout: null,
  modalVisualization: 'login',
  forcePasswordChange: false,
}

export type AuthStore = AuthActions & AuthState

export const useAuth = create<AuthStore>(
  (persist as Persistor)(
    (set, get) => ({
      ...initialState,

      async signIn(credentials, captchaToken) {
        set({ loading: LoadingStatus.Pending })

        let res: AxiosResponse | undefined

        try {
          res = await authApi.login(credentials, captchaToken)
        } catch (error) {
          logError(error)

          if (error instanceof AxiosError) {
            res = error.response
          }
        }

        if (!res?.data) {
          set({ loading: LoadingStatus.Failure })

          return
        }

        // Common errors (API pattern)
        if (isBusinessError(res.data)) {
          set({
            loading: LoadingStatus.Failure,
            error: res.data.errors
              .map((q) => q.message)
              .toString()
              .split(',')
              .join(', '),
          })

          return
        }

        // Force a new password (rule)
        if (res?.data?.user?.forcePasswordChange) {
          set({
            data: { auth: res.data.auth },
            forcePasswordChange: true,
            loading: LoadingStatus.Idle,
            modalVisualization: 'changePassword',
            opened: true,
          })

          return
        }

        set({
          data: res?.data,
          forcePasswordChange: false,
          isAuthenticated: true,
          loading: LoadingStatus.Success,
          opened: false,
        })
        get().hydrate()
      },

      signOut() {
        set((prev) => ({ ...initialState, opened: prev.opened }))
      },

      open(visualization) {
        set((prev) => ({
          opened: true,
          modalVisualization: visualization || prev.modalVisualization,
        }))
      },

      close() {
        // TODO: cancel ongoing requests
        set({ opened: false, loading: LoadingStatus.Idle })
      },

      async refreshSession() {
        const data = get().data

        if (!data) return
        const { auth } = data

        try {
          const response = await authApi.refreshSession(
            auth.token,
            auth.refresh
          )

          set({
            data: {
              ...data,
              auth: {
                token: response.data.token,
                refresh: response.data.refresh,
              },
            },
          })

          get().hydrate()
        } catch (e) {
          logError(e)
          throw e
        }
      },

      hydrate() {
        try {
          const ConnectionError = new Error('Disconected: session expired.')
          const data = get().data

          if (!data) throw ConnectionError

          const apiKey = getApiPublicKey()

          const { payload } = verify(apiKey, data.auth.token, {
            validatePayload: false, // temp
          })

          if (!payload.exp) return

          const nowTimestamp = new Date().getTime()
          const expTimestamp = new Date(payload.exp).getTime()

          if (nowTimestamp > expTimestamp) throw ConnectionError

          get().scheduleRefreshSession(expTimestamp - nowTimestamp - 5000)
        } catch (error) {
          get().signOut()
        }
      },

      scheduleRefreshSession(time: number) {
        const { refreshSessionTimeout, refreshSession } = get()

        refreshSessionTimeout && clearTimeout(refreshSessionTimeout)

        if (import.meta.env.NODE_ENV !== 'test')
          set({
            refreshSessionTimeout: setTimeout(() => {
              refreshSession()
            }, time),
          })
      },

      changeVisualization(visualization: ModalType) {
        set({ modalVisualization: visualization })
      },

      completePasswordChange() {
        set({
          forcePasswordChange: false,
          opened: false,
          loading: LoadingStatus.Idle,
        })
      },
    }),
    {
      name: 'auth',
      partialize: (state) => ({ ...state, opened: false }),
    }
  )
)

export const getUser = () => useAuth.getState().data?.user
export const isDefaultPassword = () => useAuth.getState().forcePasswordChange
