import { useEffect, useState } from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { Box, Button, IconButton, InputAdornment, styled } from '@mui/material'
import { useI18n } from 'i18n'
import {
  CheckboxButtonGroup,
  FormContainer,
  TextFieldElement,
} from 'react-hook-form-mui'

import { useSnackbar } from 'components/ui/Snackbar'
import { useAuth } from 'features/Auth'
import { getValidCaptchaToken } from 'features/Captcha/utils'
import { CaptchaWidget } from 'features/Captcha/Widget'
import { events, identify, track } from 'service/track'

import { LoadingStatus } from '../../../constants'

const buttonProps: LoadingButtonProps = {
  size: 'large',
  fullWidth: true,
  sx: {
    height: 48,
  },
}

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  form: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    gap: theme.iclinic.spacing.md,
  },
}))

type LoginFormFields = {
  password: string
  email: string
  checkboxes: string[]
}

function RecoveryPasswordButton() {
  const { changeVisualization } = useAuth()

  return (
    <Button
      variant="text"
      onClick={() => {
        changeVisualization('recoverPassword')
        track(events.clickedForgotPassword())
      }}
    >
      Esqueci a senha
    </Button>
  )
}

export function Form() {
  const [hidePassword, setHidePassword] = useState(true)
  const { signIn, loading, error } = useAuth()
  const { show } = useSnackbar()
  const { t } = useI18n()

  useEffect(() => {
    if (loading === LoadingStatus.Failure)
      show({
        message: error || t('features.auth.login.defaultErrorMessage'),
        severity: 'error',
      })
    if (loading === LoadingStatus.Success) {
      identify()
      track(events.loggedIn())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error])

  const handlePasswordToggle = () => {
    setHidePassword((prev) => !prev)
    track(events.toggledPasswordVisibility())
  }

  const handleSubmit = ({ email, password }: LoginFormFields) => {
    if (!email || !password) return

    signIn({ email, password }, getValidCaptchaToken())
  }

  return (
    <Container>
      <FormContainer onSuccess={handleSubmit}>
        <CaptchaWidget action="login" />

        <TextFieldElement fullWidth name="email" label="E-mail" required />
        <TextFieldElement
          fullWidth
          name="password"
          type={hidePassword ? 'password' : 'text'}
          variant="outlined"
          label="Senha"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handlePasswordToggle}
                  edge="end"
                >
                  {hidePassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
        />

        <div>
          <RecoveryPasswordButton />
        </div>

        <CheckboxButtonGroup
          name="checkboxes"
          options={[{ id: 'keepAlive', label: 'Mantenha-me conectado' }]}
        />

        <LoadingButton
          loading={loading === LoadingStatus.Pending}
          {...buttonProps}
          variant="contained"
          type="submit"
        >
          Entrar
        </LoadingButton>
      </FormContainer>
    </Container>
  )
}
