export const ptBr = <const>{
  title: {
    default: 'Itens da receita',
    confirmSelection: 'Confirme os itens selecionados',
  },
  protectedTitle: 'Acesso Bloqueado',
  protectedContent:
    'Para visualizar a receita, crie o seu cadastro ou acesse sua conta.',
  preventUndoDispensationTooltip:
    'Apenas a loja dispensadora pode desfazer uma dispensação.',
  dispensateError: 'Não foi possível dispensar a receita',
  undoDispensationError: 'Não foi possível desfazer a dispensação',
  primaryButton: {
    login: 'Acesse',
    disabled: 'Selecione acima',
    dispensate_one: 'Dispensar item',
    dispensate_other: 'Dispensar itens',
    undoDispensation: 'Desfazer dispensação',
    confirmDispesation: 'Finalizar e gerar PDF',
    replaceItem: 'Substituir item',
  },
  secondaryButton: {
    backToSearch: 'Voltar',
    cancel: 'Cancelar',
    reviewItens: 'Revisar itens',
    downloadCopy: 'Imprimir registro',
  },
}
