import { StoreRounded } from '@mui/icons-material'
import { Box } from '@mui/material'

import { Heading } from 'components/ui/Typography'
import { useAuth } from 'features/Auth'

export function UserInfo() {
  const { data } = useAuth()

  return (
    <Box display="flex" alignItems="center">
      <StoreRounded />
      <Heading variant="xs" mx={1}>
        {data?.pharmacy?.companyName}
      </Heading>
    </Box>
  )
}
