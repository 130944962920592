import {
  ChangePasswordParams,
  ChangePasswordResponse,
  RequestPasswordRecoveryEmailParams,
  ResetPasswordParams,
  ResetPasswordResponse,
} from 'features/Auth/types'
import apiRoutes from 'service/api-routes'

import { protectedApi } from '../service'
import { xCaptchaToken } from '../utils'

const pharmacistsApi = {
  changePassword: (params: ChangePasswordParams) =>
    protectedApi.patch<ChangePasswordResponse>(
      apiRoutes.changePassword,
      params
    ),

  requestPasswordRecoveryEmail: ({
    email,
    captchaToken,
  }: RequestPasswordRecoveryEmailParams) =>
    protectedApi.patch(
      apiRoutes.requestPasswordRecoveryEmail(email),
      undefined,
      { headers: xCaptchaToken(captchaToken) }
    ),

  resetPassword: ({ captchaToken, ...params }: ResetPasswordParams) =>
    protectedApi.patch<ResetPasswordResponse>(apiRoutes.resetPassword, params, {
      headers: xCaptchaToken(captchaToken),
    }),
}

export default pharmacistsApi
