import { LatestDispensationsResponse } from 'features/LatestDispensations/types'
import apiRoutes from 'service/api-routes'

import { protectedApi } from '../service'

const dispensationApi = {
  createDispensation: (payload: { prescriptionId: string; items: string[] }) =>
    protectedApi.post(apiRoutes.createDispensation, JSON.stringify(payload)),

  deleteDispensation: (id: UUID) =>
    protectedApi.delete(apiRoutes.deleteDispensation(id)),

  downloadDispensationRx: (dispensationId: string) =>
    protectedApi.post(
      apiRoutes.downloadDispensationRx,
      JSON.stringify({ dispensationId }),
      {
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      }
    ),

  downloadPdf: (url: string) =>
    protectedApi.get(url, {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    }),

  getLatestDispensations: () =>
    protectedApi.get<LatestDispensationsResponse>(
      apiRoutes.getLatestDispensations
    ),
}

export default dispensationApi
