import React, { FocusEventHandler } from 'react'

import { TextField, TextFieldProps } from '@mui/material'
import { ChangeHandler, FieldError, UseFormRegister } from 'react-hook-form'
import InputMask from 'react-input-mask'

type InputMaskProps = Pick<TextFieldProps, 'sx'> & {
  name: string
  error: FieldError | undefined
  label: string
  mask: string
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange?: ChangeHandler
  inputRef?: React.Ref<HTMLInputElement>
  register: ReturnType<UseFormRegister<object>>
}

export function TextFieldMask({
  name,
  label,
  error,
  mask,
  onChange,
  register,
}: InputMaskProps) {
  const forwardProps = register(name)

  return (
    <InputMask
      {...forwardProps}
      mask={mask}
      onChange={(e) => {
        onChange && onChange(e)
        forwardProps.onChange(e)
      }}
      maskChar=""
    >
      {({ inputRef, onChange, onBlur, ...inputProps }: InputMaskProps) => (
        <TextField
          {...inputProps}
          label={label}
          variant="outlined"
          fullWidth
          error={!!error}
          helperText={error?.message || ''}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={inputRef}
        />
      )}
    </InputMask>
  )
}
