import { useCallback, useEffect, useRef } from 'react'

import { useOnReactAppInit } from 'hooks/useAppInited'

import { useAuth } from 'features/Auth/useAuth'
import { AuthModal } from 'screens/Auth.modal'
import { initializeSession } from 'service/session'

import { AppRouter } from './AppRouter'

export function AppRoot() {
  const { hydrate, open, forcePasswordChange } = useAuth()

  const initializeApp = useCallback(() => {
    initializeSession()
    hydrate()
  }, [hydrate])

  useOnReactAppInit(initializeApp)

  const refForcePasswordChange = useRef(forcePasswordChange)

  useEffect(() => {
    if (forcePasswordChange && !refForcePasswordChange.current) {
      refForcePasswordChange.current = true
      open('changePassword')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcePasswordChange])

  return (
    <>
      <AuthModal />
      <AppRouter />
    </>
  )
}
