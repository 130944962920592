import { create } from 'utils'

type LoginPopoverState = {
  anchor: HTMLElement | null
}

type LoginPopoverActions = {
  open: (anchor: HTMLElement) => void
  close: () => void
}

type LoginPopoverStore = LoginPopoverState & LoginPopoverActions

const initialState: LoginPopoverState = {
  anchor: null,
}

export const useLoginPopover = create<LoginPopoverStore>((set) => ({
  ...initialState,
  open(anchor) {
    set({ anchor })
  },
  close() {
    set({ anchor: null })
  },
}))
