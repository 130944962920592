import { ArrowBackIosNewRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useResponsive } from 'hooks/useResponsive'

import { TwoColumns } from 'components/core/PageLayout'
import { ResponsiveBox } from 'components/ui/ResponsiveBox'
import { Heading } from 'components/ui/Typography'

import { Backdrop, ModalBody } from './styled'

type ModalProps = {
  title: string
  onClose: () => void
}

export function Modal({ children, title, onClose }: WithChildren<ModalProps>) {
  const isDesktop = useResponsive({ orientation: 'up', size: 'md' })
  const isMobile = useResponsive({ size: 'sm' })

  const body = (
    <ModalBody sx={isMobile ? { height: '100%', width: '100%' } : {}}>
      <IconButton onClick={onClose}>
        <ArrowBackIosNewRounded />
      </IconButton>
      <Heading component="p" variant="md" width="100%">{title}</Heading>
      {children}
    </ModalBody>
  )

  return (
    <Backdrop open>
      <ResponsiveBox
        sm={{ height: '100%', maxHeight: '100%', width: '100%' }}
        mb={{ maxHeight: '95vh' }}
        sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
      >
        {isMobile ? body : (
          <TwoColumns sx={{ alignItems: isDesktop ? 'flex-start' : 'center' }}>
            {body}
            <div />
          </TwoColumns>
        )}
      </ResponsiveBox>
    </Backdrop>
  )
}
