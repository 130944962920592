import authApi from './auth'
import cepApi from './cep'
import dispensationApi from './dispensation'
import healthCheckApi from './health-check'
import pharmacistsApi from './pharmacists'
import pharmacyApi from './pharmacy'
import prescriptionApi from './prescription'
import productsApi from './products'
import validationApi from './validation'

export { getAuthToken } from './service'

export const api = {
  ...authApi,
  ...cepApi,
  ...dispensationApi,
  ...healthCheckApi,
  ...pharmacistsApi,
  ...pharmacyApi,
  ...prescriptionApi,
  ...productsApi,
  ...validationApi,
}
