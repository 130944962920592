import { createTheme } from '@mui/material'
import { axiomTheme, border, color, font, line } from '@iclinic/rx-theme'

export const customFonts = [
  font.family.base,
  'Helvetica',
  'Arial',
  'sans-serif',
].join(',')

const common = {
  black: color.neutral[999],
  white: color.neutral[0],
}

const primary = {
  ...color.primary,
  contrastText: common.white,
}

const secondary = {
  ...color.secondary,
  contrastText: primary.dark,
}

const grey = {
  50: color.neutral[50],
  100: color.neutral[100],
  200: color.neutral[300],
  300: color.neutral[300],
  400: color.neutral[500],
  500: color.neutral[500],
  600: color.neutral[700],
  700: color.neutral[700],
  800: color.neutral[900],
  900: color.neutral[900],
}

const text = {
  primary: color.neutral[900],
  secondary: color.neutral[700],
  disabled: color.neutral[300],
  hint: color.neutral[500],
}

const background = {
  paper: common.white,
  default: common.white,
}

export const muiThemeVariant = createTheme({
  palette: {
    // pass only main color so mui can create a color scale based on it
    // iclinic light vs mui light have a huge difference, and,
    // some components does breaks, like mui <Alert />
    info: { main: color.feedback.info.main },
    error: { main: color.feedback.error.main },
    success: { main: color.feedback.success.main },
    warning: { main: color.feedback.warning.main },
    common,
    primary,
    secondary,
    grey,
    text,
    background,
    divider: color.neutral[100],
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
  },
  spacing: 8,
  typography: {
    htmlFontSize: parseInt(font.size.xs, 10),
    fontFamily: customFonts,
    fontSize: parseInt(font.size.xxs, 10),
    h1: {
      fontFamily: customFonts,
      fontWeight: font.weight.bold,
      fontSize: font.size.xxl,
      lineHeight: line.height.xxl,
      color: color.neutral[900],
    },
    h2: {
      fontFamily: customFonts,
      fontWeight: font.weight.bold,
      fontSize: font.size.xl,
      lineHeight: line.height.xl,
      color: color.neutral[900],
    },
    h3: {
      fontFamily: customFonts,
      fontWeight: font.weight.bold,
      fontSize: font.size.lg,
      lineHeight: line.height.lg,
      color: color.neutral[700],
    },
    h4: {
      fontFamily: customFonts,
      fontWeight: font.weight.bold,
      fontSize: font.size.md,
      lineHeight: line.height.md,
      color: color.neutral[700],
    },
    h5: {
      fontFamily: customFonts,
      fontWeight: font.weight.medium,
      fontSize: font.size.xs,
      lineHeight: line.height.md,
      color: color.neutral[700],
    },
    h6: {
      fontFamily: customFonts,
      fontWeight: font.weight.bold,
      fontSize: font.size.xs,
      lineHeight: line.height.md,
      color: color.neutral[700],
    },
    subtitle1: {
      fontFamily: customFonts,
      fontWeight: font.weight.regular,
      fontSize: font.size.sm,
      lineHeight: line.height.md,
      color: color.neutral[700],
    },
    subtitle2: {
      fontFamily: customFonts,
      fontWeight: font.weight.regular,
      fontSize: font.size.xs,
      lineHeight: line.height.md,
      color: color.neutral[700],
    },
    body1: {
      fontFamily: customFonts,
      fontWeight: font.weight.regular,
      fontSize: font.size.sm,
      lineHeight: line.height.md,
      color: color.neutral[700],
    },
    body2: {
      fontFamily: customFonts,
      fontWeight: font.weight.regular,
      fontSize: font.size.xs,
      lineHeight: line.height.md,
      color: color.neutral[700],
    },
    button: {
      fontFamily: customFonts,
      fontWeight: font.weight.medium,
      fontSize: font.size.xs,
      lineHeight: line.height.sm,
      letterSpacing: '0.02857em',
      textTransform: 'none',
    },
    caption: {
      fontFamily: customFonts,
      fontWeight: font.weight.regular,
      fontSize: font.size.xxxs,
      lineHeight: line.height.sm,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: customFonts,
      fontWeight: font.weight.medium,
      fontSize: font.size.xxxs,
      lineHeight: line.height.lg,
      textTransform: 'uppercase',
      color: color.neutral[700],
      letterSpacing: '0.0625rem',
    },
  },
  shape: {
    borderRadius: parseInt(border.radius.sm, 10),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: axiomTheme.spacing[2],
        },
        containedSecondary: {
          backgroundColor: '#D9EBFC',
          color: '#003F72',
          fontSize: axiomTheme.font.size.xxs,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },

      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid ${axiomTheme.color.border.subtle}`,
            boxShadow: 'none',
            backgroundColor: axiomTheme.color.surface.subtle.default,
          },
        },
      ],
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingRight: axiomTheme.spacing[4],
          paddingLeft: axiomTheme.spacing[4],
          paddingTop: axiomTheme.spacing[6],
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: axiomTheme.font.size.xxs,
          color: axiomTheme.color.link.default,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedWarning: {
          backgroundColor: axiomTheme.color.surface.feedback.warning.subtle,
          color: axiomTheme.color.typeface.feedback.warning,
          borderColor: axiomTheme.color.border.feedback.warning,
        },
      },
    },
  },
})
