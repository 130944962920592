import  { useEffect, useState } from 'react'

import healthCheckApi from 'service/api/health-check'

export const useReachability = () => {
  const [isOnline, setIsOnline] = useState(true)

  const isReachable = async () => {
    try {
      await healthCheckApi.healthCheck()

      setIsOnline(true)
    } catch (error) {
      setIsOnline(false)
    }
  }

  useEffect(() => {
    isReachable()
  }, [])

  return isOnline
}
