export const ptBr = <const>{
  title: 'Detalhes da receita',
  searchField: {
    label: 'Digite o ID',
    prefix: 'rx-',
    hint: 'Exemplo: "879PL977"',
  },
  notFoundContent: {
    title: 'Não encontramos a receita',
    description: 'Confira o código e tente novamente.',
    subDescription:
      'Se a receita não for iClinic Rx, use outros serviços de validação',
    linkText: 'Acesse o site do iti',
  },
  successContent: {
    title: 'Receita Dispensada com sucesso!',
    description:
      'A receita ficará com um novo status de dispensada, com a data e a farmácia responsável.',
  },
  submit: 'Consultar',
  notFound: 'Receita não encontrada!',
}
