const API_VERSION = 'v1'

const apiRoutes = {
  login: '/auth/login',
  refreshSession: '/auth/refresh',
  requestPasswordRecoveryEmail: (email: string) =>
    `/${API_VERSION}/pharmacists/account/recovery/${email}`,
  resetPassword: `/${API_VERSION}/pharmacists/password`,
  changePassword: `/${API_VERSION}/pharmacists/change-password`,
  cep: (cep: string) => `/${API_VERSION}/cep/${cep}`,
  validateCnpj: (cnpj: string) => `/${API_VERSION}/validate/cnpj/${cnpj}`,
  healthcheck: '/healthcheck',
  createDispensation: `/${API_VERSION}/dispensations`,
  deleteDispensation: (id: string) => `${API_VERSION}/dispensations/${id}`,
  getLatestDispensations: `/${API_VERSION}/dispensations/latest/user`,
  searchRx: (code: string) => `/${API_VERSION}/prescriptions/${code}`,
  previewRx: (code: string) => `/${API_VERSION}/prescriptions/preview/${code}`,
  replacementRx: (id: string) => `/${API_VERSION}/product/${id}`,
  downloadDispensationRx: `/${API_VERSION}/dispensations/printer/pdf/dispensation`,
  register: `/${API_VERSION}/pharmacies`,
}

export default apiRoutes
