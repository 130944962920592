import apiRoutes from 'service/api-routes'

import { protectedApi } from '../service'

const validationApi = {
  validateCnpj: (cnpj: string) =>
    protectedApi.get(apiRoutes.validateCnpj(cnpj)),
}

export default validationApi
