import { Button, darken, styled } from '@mui/material'

import { useRolloutInbound } from '../RolloutInbound/store'

export const RolloutCta = styled(Button)(({ theme }) => ({
  backgroundColor: theme.axiom.color.highlight.ruby[2],
  borderRadius: theme.axiom.border.radius.xl,
  padding: '0.5rem 1rem',
  color: '#ffffff',
  marginLeft: '0.8rem',
  marginTop: '0.25rem',
  '&:hover': {
    backgroundColor: darken(theme.axiom.color.highlight.ruby[2], 0.25),
  },
}))

RolloutCta.defaultProps = {
  color: 'primary',
  variant: 'contained',
  onClick: useRolloutInbound.getState().openModal,
}
