import { AxiosError } from 'axios'
import { translate as t } from 'i18n'

import { ptBr } from 'features/Auth/locales/ptBr'
import { WithCaptchaToken } from 'features/Captcha/types'
import { api } from 'service/api'
import { logError } from 'service/logger'

import { AuthRegisterError } from './errors'
import {
  ChangePasswordParams,
  RegisterParams,
  RequestPasswordRecoveryEmailParams,
  ResetPasswordParams,
} from './types'

const Status200Regex = /^2\d{2}$/

export const mutations = {
  async changePassword({
    currentPassword,
    newPassword,
    confirmNewPassword,
  }: ChangePasswordParams) {
    const response = await api.changePassword({
      currentPassword,
      newPassword,
      confirmNewPassword,
    })

    if (!Status200Regex.test(`${response.status}`)) {
      const errorMessage =
        response.data?.errors[0].message || ptBr.changePassword.feedback.error

      throw new Error(errorMessage)
    }

    return response
  },

  async requestPasswordRecoveryEmail(
    payload: RequestPasswordRecoveryEmailParams
  ) {
    try {
      return await api.requestPasswordRecoveryEmail(payload)
    } catch (error) {
      logError(error)
      throw new Error(t('features.auth.recoverPassword.feedback.error'))
    }
  },

  async resetPassword(payload: ResetPasswordParams) {
    try {
      return await api.resetPassword(payload)
    } catch (error) {
      logError(error)

      let message: string

      if (error instanceof AxiosError)
        message = error.response?.data?.errors?.[0]?.message

      message ||= t('features.auth.resetPassword.feedback.error')

      throw new Error(message)
    }
  },

  async register(params: WithCaptchaToken<RegisterParams>) {
    try {
      const response = await api.register(params)

      if (Status200Regex.test(`${response.status}`)) return response

      throw new AuthRegisterError({ cause: response.data })
    } catch (e) {
      logError(e)
      if (e instanceof AxiosError)
        throw new AuthRegisterError({ cause: e.response?.data })
      throw e
    }
  },

  async cep(cep: string) {
    const response = await api.searchAddress(cep)

    if (!Status200Regex.test(`${response.status}`))
      throw new Error('Error resetting password')

    return response
  },

  async validateCnpj(cnpj: string) {
    const response = await api.validateCnpj(cnpj)

    if (!Status200Regex.test(`${response.status}`))
      throw new Error('Error validating the cnpj')

    return {}
  },
}
