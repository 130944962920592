import { v4 as uuidv4 } from 'uuid'

const KEYS = {
  sessionId: 'sessionId',
} as const

const _setSessionId = () => {
  sessionStorage.setItem(KEYS.sessionId, uuidv4())
}

const getSessionId = () => sessionStorage.getItem(KEYS.sessionId) || undefined

const initializeSession = () => {
  const sessionId = getSessionId()

  if (!sessionId) _setSessionId()
}

export { getSessionId, initializeSession }
