import { Checkbox } from '@mui/material'
import { Box, BoxProps } from '@mui/system'
import { useI18n } from 'i18n'

import { Body } from 'components/ui/Typography'
import { events, track } from 'service/track'

const containerProps: BoxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  my: 2,
}

type TermsAndPolicies = {
  checked: boolean
  onChange: () => void
}

export function TermsAndPolicies({ checked, onChange }: TermsAndPolicies) {
  const { t } = useI18n()
  const origin = 'registerForm'
  const [termsText, privacyText] = [
    t('layout.footer.termsOfUse.title'),
    t('layout.footer.privatePolicy.title'),
  ]

  return (
    <Box {...containerProps} onClick={onChange}>
      <Checkbox checked={checked} />
      <Body variant="xs" component="label">
        {t('features.auth.register.form.checkbox.termsAndPolicies')}{' '}
        <a
          target="_blank"
          href={t('layout.footer.termsOfUse.link')}
          title={termsText}
          rel="noreferrer"
          onClick={() => track(events.openedToU({ origin }))}
        >
          {termsText.toLowerCase()}
        </a>
        {' e '}
        <a
          target="_blank"
          href={t('layout.footer.privatePolicy.link')}
          rel="noreferrer"
          title={privacyText}
          onClick={() => track(events.openedPP({ origin }))}
        >
          {privacyText.toLowerCase()}
        </a>
        .
      </Body>
    </Box>
  )
}
