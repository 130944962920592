import { RxResponse } from 'features/RxSearch/types'

import { BusinessError } from './types'

export function xCaptchaToken(token: string) {
  return {
    'X-Captcha-Token': token,
  }
}

export function isBusinessError(error: object): error is BusinessError {
  return 'errors' in error
}

export function isRxResponse(data: unknown): data is RxResponse {
  return !!data && typeof data === 'object' && 'prescription' in data
}

export function sanitizeRxResponse(json: RxResponse): RxResponse {
  /*
   * TODO: temp mapping response to avoid breaking-change
   * waiting for "exams" feature definition to be implemented
   *
   * [legacy code]
   */
  return {
    ...json,
    prescription: {
      ...json.prescription,
      items: json.prescription.items ?? [],
    },
    dispensation: {
      ...json.dispensation,
      items: json.dispensation.items ?? [],
    },
  }
}
