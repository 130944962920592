import { Box, BoxProps, Button, styled } from '@mui/material'
import { useI18n } from 'i18n'

import { useAuth } from 'features/Auth'
import { RolloutCta } from 'features/Rollout/components/RolloutCta'
import { events, track } from 'service/track'

import { UserInfo } from './UserInfo'

const containerProps: BoxProps = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  gap: '4px',
}

const Nav = styled('nav')(({ theme }) => ({
  display: 'flex',
  gap: theme.iclinic.font.size.xxxs,
}))

function HelpButton() {
  const { t } = useI18n()

  return (
    <Button
      variant="text"
      onClick={() => track(events.openedHelp({ origin: 'header' }))}
      href={t('layout.header.menu.helpLink')}
      target="_blank"
    >
      {t('layout.header.menu.help')}
    </Button>
  )
}

const RolloutButton = () =>
  <RolloutCta>Cadastre toda sua rede</RolloutCta>

export function Navbar() {
  const { isAuthenticated, open, signOut, changeVisualization } = useAuth()
  const { t } = useI18n()
  const origin = 'header'

  const handleSignUp = () => {
    track(events.openedRegisterForm({ origin }))
    changeVisualization('register')
    open()
  }

  const handleLogin = () => {
    open()
    track(events.openedLoginModal({ origin }))
  }

  const handleLogout = () => {
    signOut()
    track(events.loggedOut({ origin }))
  }

  if (isAuthenticated)
    return (
      <Box {...containerProps}>
        <UserInfo />
        <Button variant="text" onClick={handleLogout}>
          {t('layout.header.menu.signOut')}
        </Button>
        <HelpButton />
        <RolloutButton />
      </Box>
    )

  return (
    <Box {...containerProps}>
      <Nav>
        <Button variant="text" onClick={handleSignUp}>
          {t('layout.header.menu.signUp')}
        </Button>
        <Button variant="text" onClick={handleLogin}>
          {t('layout.header.menu.signIn')}
        </Button>
        <HelpButton />
        <RolloutButton />
      </Nav>
    </Box>
  )
}
