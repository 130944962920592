import { useState } from 'react'

import {
  ChevronLeft,
  HelpOutlineRounded,
  LoginRounded,
  LogoutRounded,
  MenuRounded,
  StoreRounded,
} from '@mui/icons-material'
import {
  Backdrop,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Divider,
  IconButton,
  styled,
} from '@mui/material'
import { useI18n } from 'i18n'

import { AppVersion } from 'components/core/AppVersion'
import { UserInfo } from 'components/core/Header/UserInfo'
import { useAuth } from 'features/Auth'
import { events, track } from 'service/track'
import { transientOptions } from 'styles/utils'

import Logo from './afya-iclinic-rx.svg?react'

const textButtonProps: ButtonProps = {
  variant: 'text',
  sx: {
    px: 0,
    justifyContent: 'flex-start',
  },
}

const Container = styled(
  Box,
  transientOptions
)<BoxProps & { $opened?: boolean }>(({ theme, $opened = false }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  padding: `${theme.spacing(6)} ${theme.spacing(2)} ${theme.spacing(3)}`,
  position: 'absolute',
  top: 0,
  left: $opened ? 0 : '-100vw',
  transition: '0.4s 0s ease-out',
  width: '80vw',
  maxWidth: 300,
  height: '100vh',
  backgroundColor: theme.palette.common.white,
  zIndex: 999,
}))

export function SideMenu() {
  const { isAuthenticated, open, signOut, changeVisualization } = useAuth()
  const [opened, setOpened] = useState(false)
  const { t } = useI18n()
  const origin = 'sidebar'

  const toggleVisibility = () => {
    setOpened((prev) => !prev)
  }

  const handleSignUp = () => {
    track(events.openedRegisterForm({ origin }))
    setOpened(false)
    changeVisualization('register')
    open()
  }

  const handleSignIn = () => {
    setOpened(false)
    open()
    track(events.openedLoginModal({ origin }))
  }

  const handleSignOut = () => {
    signOut()
    track(events.loggedOut({ origin }))
  }

  return (
    <>
      <IconButton onClick={toggleVisibility} sx={{ mr: 1 }}>
        <MenuRounded />
      </IconButton>

      <Backdrop open={opened} sx={{ zIndex: 999 }} onClick={toggleVisibility} />

      <Container $opened={opened}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" alignItems="center">
            <IconButton onClick={toggleVisibility}>
              <ChevronLeft />
            </IconButton>
            <Logo style={{ maxWidth: '100%', width: '180px' }} title={t('genericTerms.appName')} />
          </Box>

          {isAuthenticated ? (
            <>
              <UserInfo />
              <Button
                onClick={handleSignOut}
                {...textButtonProps}
                startIcon={<LogoutRounded />}
              >
                {t('layout.header.menu.signOut')}
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={handleSignUp}
                startIcon={<StoreRounded />}
                {...textButtonProps}
              >
                {t('layout.header.menu.signUp')}
              </Button>

              <Button
                onClick={handleSignIn}
                startIcon={<LoginRounded />}
                {...textButtonProps}
              >
                {t('layout.header.menu.signIn')}
              </Button>
            </>
          )}
          <Divider />
          <Button
            variant="text"
            target="_blank"
            href={t('layout.header.menu.helpLink')}
            onClick={() => track(events.openedHelp({ origin: 'header' }))}
            startIcon={<HelpOutlineRounded />}
            sx={textButtonProps.sx}
          >
            {t('layout.header.menu.help')}
          </Button>
        </Box>

        <AppVersion trackReference="sidebar" />
      </Container>
    </>
  )
}
