import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { styled } from '@mui/material'

export const SubmitButton = styled(LoadingButton)<LoadingButtonProps>({
  height: 48,
})

SubmitButton.defaultProps = {
  size: 'large',
  fullWidth: true,
  variant: 'contained',
  type: 'submit',
}
