import { Credentials } from 'features/Auth'
import { LoginResponse, RefreshTokenResponse } from 'features/Auth/types'
import apiRoutes from 'service/api-routes'

import { protectedApi } from '../service'
import { xCaptchaToken } from '../utils'

const authApi = {
  login(credentials: Credentials, captchaToken: string) {
    return protectedApi.post<LoginResponse>(apiRoutes.login, credentials, {
      headers: xCaptchaToken(captchaToken),
    })
  },

  refreshSession(token: string, refresh: string) {
    return protectedApi.post<RefreshTokenResponse>(
      apiRoutes.refreshSession,
      JSON.stringify({ token, refresh })
    )
  },
}

export default authApi
