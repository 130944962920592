import { RefObject, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, styled } from '@mui/material'
import { useI18n } from 'i18n'
import {
  ErrorOption,
  FormContainer,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { useMutation } from 'react-query'
import * as Yup from 'yup'

import { useSnackbar } from 'components/ui/Snackbar'
import { TextFieldMask } from 'components/ui/TextFieldMask/TextFieldMask'
import { Heading } from 'components/ui/Typography'
import { useAuth } from 'features/Auth'
import { SubmitButton } from 'features/Auth/Register'
import { TermsAndPolicies } from 'features/Auth/Register/Form/TermsAndPolicies'
import {
  getCnpjSchema,
  getFormSchema,
  getZipCodeSchema,
} from 'features/Auth/Register/Form/utils'
import { ErrorCause, ErrorField } from 'features/Auth/types'
import { getValidCaptchaToken } from 'features/Captcha/utils'
import { CaptchaWidget } from 'features/Captcha/Widget'
import { events, track } from 'service/track'
import { onlyLetNumbers } from 'utils/string'

import { mutations } from '../../mutations'

const FormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.iclinic.spacing.sm,
}))

const Fieldset = styled('fieldset')(({ theme }) => ({
  border: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.iclinic.spacing.sm,
  '&:not(:last-child)': {
    marginBottom: theme.iclinic.spacing.lg,
  },
}))

const Container = styled(Box)({
  flex: 'auto 1 1',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  form: {
    flex: 'auto 1 1',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
})

function FieldsetTitle({ children }: { children: string }) {
  return (
    <Heading variant="xs" mb={2} component="legend">
      {children}
    </Heading>
  )
}

type FormFields = Yup.InferType<ReturnType<typeof getFormSchema>>

export function Form() {
  const [checked, setChecked] = useState(false)
  const { changeVisualization } = useAuth()
  const { show } = useSnackbar()
  const { t } = useI18n()
  const formRef: RefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null)

  const { mutate: mutateCep } = useMutation(mutations.cep, {
    onSuccess({ data }) {
      setValue('address.city', data.city)
      trigger('address.city')
      setValue('address.state', data.state)
      trigger('address.state')
      setValue('address.neighborhood', data.neighborhood)
      trigger('address.neighborhood')
      setValue('address.street', data.street)
      trigger('address.street')
    },
    onError: () => {
      setError('address.zipCode', {
        type: 'custom',
        message: t('features.auth.register.validations.cepInvalid'),
      })
    },
  })

  const showErrors = (error: Error) => {
    if (error.cause) {
      const cause: ErrorCause = error.cause

      cause.errors?.forEach((error: ErrorField) => {
        if (error?.field) {
          setError(error?.field, {
            type: 'custom',
            message: error.message,
          })
        } else {
          show({
            message: error.message,
            severity: 'error',
          })
        }
      })
    } else {
      show({
        message: t('features.auth.register.feedback.error'),
        severity: 'error',
      })
    }
  }

  const { mutate: mutateRegister, isLoading } = useMutation(
    mutations.register,
    {
      onSuccess() {
        changeVisualization('registerSuccess')
        track(events.createdNewAccount())
      },
      onError: (error: Error) => {
        showErrors(error)
        if (formRef.current) formRef.current.scrollTop = 0
      },
    }
  )

  const { mutate: mutateValidateCnpj } = useMutation(mutations.validateCnpj, {
    onError: () => {
      setError('cnpj', {
        type: 'custom',
        message: t('features.auth.register.validations.cnpjInvalid'),
      })
    },
  })

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    register: registerFormFields,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(getFormSchema()),
  })

  const searchCep: (cep: string) => Promise<void> = async (cep: string) => {
    clearErrors('address.zipCode')
    try {
      await getZipCodeSchema().validate(cep)
      mutateCep(cep)
    } catch (error) {
      setError('address.zipCode', error as ErrorOption)
    }
  }

  const validateCnpj: (cnpj: string) => Promise<void> = async (
    cnpj: string
  ) => {
    clearErrors('cnpj')
    try {
      cnpj = onlyLetNumbers(cnpj)
      await getCnpjSchema().validate(cnpj)

      mutateValidateCnpj(cnpj)
    } catch (error) {
      setError('cnpj', error as ErrorOption)
    }
  }

  const onSubmit = (data: FormFields) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { confirmEmail, confirmPassword, ...payload } = data

    mutateRegister({
      ...payload,
      captchaToken: getValidCaptchaToken(),
      address: {
        ...payload.address,
        zipCode: payload.address.zipCode.replace('-', ''),
      },
    })
  }

  return (
    <Container>
      <FormContainer>
        <CaptchaWidget action="register" />

        <Box
          ref={formRef}
          flex="auto 0 1"
          overflow="hidden scroll"
          pr={1}
          mt={1}
        >
          <Fieldset>
            <FieldsetTitle>
              {t('features.auth.register.pharmacyField')}
            </FieldsetTitle>

            <TextFieldElement
              control={control}
              fullWidth
              name="companyName"
              label={t('features.auth.register.form.companyName')}
              required
            />

            <TextFieldMask
              mask="99.999.999/9999-99"
              name="cnpj"
              register={registerFormFields}
              error={errors?.cnpj}
              onChange={(input) => validateCnpj(input.target.value)}
              label={t('features.auth.register.form.cnpj')}
            />

            <TextFieldElement
              control={control}
              fullWidth
              label={t('features.auth.register.form.email')}
              helperText={t('features.auth.register.emailHint')}
              name="email"
              type="email"
              required
            />

            <TextFieldElement
              control={control}
              fullWidth
              label={t('features.auth.register.form.confirmEmail')}
              name="confirmEmail"
              type="email"
              required
            />

            <FormRow maxWidth="48%">
              <TextFieldMask
                sx={{ flexBasis: '310px' }}
                mask="(99) 9 9999-9999"
                register={registerFormFields}
                error={errors?.phone}
                label={t('features.auth.register.form.phone')}
                name="phone"
              />
            </FormRow>
          </Fieldset>

          <Fieldset>
            <FieldsetTitle>
              {t('features.auth.register.addressPharmacyField')}
            </FieldsetTitle>

            <FormRow>
              <TextFieldMask
                onChange={(input) => searchCep(input.target.value)}
                register={registerFormFields}
                mask="99999-999"
                error={errors?.address?.zipCode}
                name="address.zipCode"
                label={t('features.auth.register.form.address.zipCode')}
              />
              <TextFieldElement
                fullWidth
                control={control}
                name="address.neighborhood"
                label={t('features.auth.register.form.address.neighborhood')}
                required
              />
            </FormRow>

            <FormRow>
              <TextFieldElement
                control={control}
                name="address.city"
                label={t('features.auth.register.form.address.city')}
                required
              />
              <TextFieldElement
                control={control}
                name="address.state"
                label={t('features.auth.register.form.address.state')}
                required
              />
            </FormRow>

            <FormRow>
              <TextFieldElement
                control={control}
                fullWidth
                name="address.street"
                label={t('features.auth.register.form.address.street')}
                required
              />
              <TextFieldElement
                sx={{ flexBasis: '140px' }}
                control={control}
                name="address.number"
                label={t('features.auth.register.form.address.number')}
                required
              />
            </FormRow>

            <TextFieldElement
              control={control}
              name="address.complement"
              label={t('features.auth.register.form.address.complement')}
            />
          </Fieldset>

          <Fieldset>
            <FieldsetTitle>
              {t('features.auth.register.passwordField')}
            </FieldsetTitle>

            <TextFieldElement
              control={control}
              fullWidth
              name="password"
              label={t('features.auth.register.form.password')}
              helperText={t('features.auth.register.passwordHint')}
              type="password"
              required
            />

            <TextFieldElement
              control={control}
              fullWidth
              name="confirmPassword"
              label={t('features.auth.register.form.confirmPassword')}
              type="password"
              required
            />
          </Fieldset>
        </Box>

        <TermsAndPolicies
          checked={checked}
          onChange={() => setChecked(!checked)}
        />

        <SubmitButton
          sx={{ flex: '48px 0 0' }}
          disabled={!checked}
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {t('features.auth.register.form.submitButton')}
        </SubmitButton>
      </FormContainer>
    </Container>
  )
}
