export const ptBr = {
  field: {
    required: 'Campo obrigatório',

    email: {
      invalid: 'E-mail inválido',
    },
    phone: {
      invalid: 'Telefone inválido',
    },
  },
}
