import { create } from 'utils'

type RolloutInboundStore = {
  isModalOpened: boolean
  closeModal(): void
  openModal(): void
}

export const useRolloutInbound = create<RolloutInboundStore>((set) => ({
  isModalOpened: false,

  closeModal() {
    set({ isModalOpened: false })
  },
  openModal() {
    set({ isModalOpened: true })
  },
}))
