import { Box } from '@mui/material'
import { useI18n } from 'i18n'

import { Body } from 'components/ui/Typography'
import { useAuth } from 'features/Auth'
import { SubmitButton } from 'features/Auth/Register'

export function Success() {
  const { t } = useI18n()
  const { changeVisualization } = useAuth()

  const handleClick = () => {
    changeVisualization('login')
  }

  return (
    <>
      <Box my={2}>
        <Body mb={1} variant="xs">
          {t('features.auth.register.feedback.success.subtitle')}
        </Body>
        <Body variant="xs">
          {t('features.auth.register.feedback.success.subtitle2')}
        </Body>
      </Box>

      <SubmitButton onClick={handleClick} color="secondary">
        {t('features.auth.register.feedback.success.submit')}
      </SubmitButton>
    </>
  )
}
