
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom'

function RoutePageLayout() {
  return <Outlet />
}

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={<RoutePageLayout />}>
    <Route path="/" lazy={() => import('screens/Home.page')} />
    <Route path="/:id" lazy={() => import('screens/RxDetails.page')} />
    <Route path="/reset-password" lazy={() => import('screens/ResetPassword.page')} />
  </Route>
))

export function AppRouter() {
  return (
    <RouterProvider router={router} />
  )
}
