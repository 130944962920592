import { CssBaseline } from '@mui/material'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { axiomTheme, iclinicTheme } from '@iclinic/rx-theme'

import { muiThemeVariant } from './iclinicTheme'

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <MUIThemeProvider theme={
      {
        ...muiThemeVariant,
        iclinic: iclinicTheme,
        axiom: axiomTheme,
      }
    }
    >
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  )
}
