import { Backdrop as MUIBackdrop, styled } from '@mui/material'

import { Card } from 'components/ui/Card'

export const Backdrop = styled(MUIBackdrop)({
  alignItems: 'flex-start',
  overflowY: 'auto',
  zIndex: 999,
})

export const ModalBody = styled(Card)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  maxHeight: '100%',
  overflow: 'auto',
})
