export const ptBr = <const>{
  itemDisplay: {
    title: {
      empty: 'Não encontramos alternativas para este medicamento',
    },
  },
  search: {
    placeholder: 'Busque o medicamento substituto',
    resultsFound: 'RESULTADOS ENCONTRADOS',
  },
}
