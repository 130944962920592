const createEvent = <T extends object>(name: string, opts = {} as T) =>
  <const>{
    name,
    ...opts,
  }

const helpSessionEvents = {
  openedItiLink: () => createEvent('Opened ITI link'),
}

const searchPrescriptionEvents = {
  searchedPrescription: (props: { rxCode: string; found: boolean }) =>
    createEvent('Searched for a prescription', props),
  clearedPrescriptionSearch: () =>
    createEvent('Cleared the prescription search field (X)'),
}

const latestDispensationsEvents = {
  openedLatestDispensation: (props: { rxCode: string }) =>
    createEvent('Opened a dispensation from the latest list', props),
}

const informationEvents = {
  clickedOnLogo: () => createEvent('Clicked on app logo'),
  openedToU: (props: { origin: 'footer' | 'sidebar' | 'registerForm' }) =>
    createEvent('Opened Terms of Use link', props),
  openedPP: (props: { origin: 'footer' | 'sidebar' | 'registerForm' }) =>
    createEvent('Opened Policy Pivacy link', props),
  openedHelp: (props: { origin: 'header' | 'sidebar' }) =>
    createEvent('Opened help link', props),
  openedFaq: (props: { answer: string }) =>
    createEvent('Opened faq answer', props),
}

type EventOrigin = 'faq' | 'header' | 'help' | 'rx-details' | 'sidebar'

const authenticationEvents = {
  openedLoginModal: (props: { origin: EventOrigin }) =>
    createEvent('Opened login modal', props),
  toggledPasswordVisibility: () => createEvent('Toggled password visibility'),
  loggedIn: () => createEvent('Logged in'),
  loggedOut: (props: { origin: 'header' | 'sidebar' }) =>
    createEvent('Logged out', props),
  openedRegisterForm: (props: { origin: EventOrigin }) =>
    createEvent('Opened on signup form', props),
  openedRecoveryPasswordModal: () =>
    createEvent('Opened recovery password modal'),
  createdNewAccount: () => createEvent('Created a new account'),
  changedDefaultPassword: () =>
    createEvent('Changed default password for a definitve password'),
  clickedForgotPassword: () => createEvent('Clicked at forgot password link'),
  requestedPasswordRecover: () =>
    createEvent('Requested a password recover email'),
  submitedNewPassword: () => createEvent('Submited form creating new password'),
}

type MedicationSummary = { id: UUID; title: string }

const detailsEvents = {
  backedToSearch: () => createEvent('Clicked back to search from rx details'),
  selectedItem: (props: { medication: MedicationSummary }) =>
    createEvent('Selected prescription item', props),
  deselectedItem: (props: { medication: MedicationSummary }) =>
    createEvent('De-selected prescription item', props),
  confirmedItemSelection: () => createEvent('Confirmed item selection'),
  backedToReviewItems: () => createEvent('Backed to review items'),
  openedFullPdf: () => createEvent('Opened prescription PDF'),
  finishedDispensation: (props: {
    dispensed: MedicationSummary[]
    notDispensed: MedicationSummary[]
    rxCode: string
  }) => createEvent('Finished prescription dispensation', props),
  canceledDispensation: (props: { rxCode: string }) =>
    createEvent('Canceled a dispensation', props),

  clickedReplaceItem: () => createEvent('Clicked to replace item'),
  selectedReplacingItem: () => createEvent('Selected replacing item'),
  removedReplacingItem: () => createEvent('Removed replacing item'),
  dispensedAtLeastOneReplacedItem: () =>
    createEvent('Dispensed at least one replaced item'),
}

const rolloutEvents = {
  openedRolloutModal: () => createEvent('Opened rollout modal'),
  clickedDownloadRolloutSheet: () =>
    createEvent('Clicked to download rollout template'),
}

/**
 * List of events tracked
 * Do not change any value unless you know what you're doing
 * It'll impact directly on https://mixpanel.com/ project dashboard
 */
export const events = {
  ...helpSessionEvents,
  ...searchPrescriptionEvents,
  ...latestDispensationsEvents,
  ...informationEvents,
  ...authenticationEvents,
  ...detailsEvents,
  ...rolloutEvents,
}

export type TrackingEvents = ReturnType<(typeof events)[keyof typeof events]>
