import { WarningAmber } from '@mui/icons-material'
import { styled } from '@mui/material'
import { useReachability } from 'hooks/useReachability'
import { useI18n } from 'i18n'

import { Card } from 'components/ui/Card'
import { Body } from 'components/ui/Typography'

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.iclinic.color.feedback.warning.light,
  alignSelf: 'center',
  maxWidth: '80%',
  marginTop: theme.iclinic.spacing.lg,
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}))

const WarningIcon = styled(WarningAmber)(({ theme }) => ({
  color: theme.iclinic.color.feedback.warning.main,
  marginRight: theme.iclinic.spacing.sm,
}))

export function WarningReachableCard() {
  const { t } = useI18n()
  const isReachable = useReachability()

  if (isReachable) return null

  return (
    <StyledCard>
      <WarningIcon />
      <Body variant="xs" sx={{ display: 'inline' }}>
        {t('layout.warningReachability.initialDescription')}
        <b>{t('layout.warningReachability.boldDescription')}</b>
        {t('layout.warningReachability.endDescription')}
      </Body>
    </StyledCard>
  )
}
