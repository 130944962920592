import { create as actualCreate, StateCreator } from 'zustand'

// Reseting store state for testing
// Reference: https://github.com/pmndrs/zustand/blob/main/docs/guides/testing.mdx

export const storeResetFns = new Set<() => void>()

export const create = <S>(createState: StateCreator<S>) => {
  const store = actualCreate(createState)
  const initialState = store.getState()

  storeResetFns.add(() => store.setState(initialState, true))

  return store
}
