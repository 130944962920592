import { Box, BoxProps } from '@mui/material'
import { useResponsive } from 'hooks/useResponsive'

type ResponsiveBoxProps = {
  sm?: BoxProps
  md?: BoxProps
  lg?: BoxProps
} & BoxProps

export function ResponsiveBox({
  sm = {},
  md = {},
  lg = {},
  children,
  ...props
}: ResponsiveBoxProps) {
  const isDeskTop = useResponsive({ size: 'lg' })
  const isTablet = useResponsive({ size: 'md' })
  const isMobile = useResponsive({ size: 'sm' })

  let responsiveProps = {}

  if (isDeskTop) responsiveProps = lg
  if (isTablet) responsiveProps = md
  if (isMobile) responsiveProps = sm

  return (
    <Box {...props} {...responsiveProps}>
      {children}
    </Box>
  )
}
