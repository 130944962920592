import { ptBrTransaltion } from 'i18n/locales'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export function initializeI18n() {
  i18n.use(initReactI18next).init({
    resources: {
      ptBr: {
        translation: ptBrTransaltion,
      },
    },
    lng: 'ptBr',
    fallbackLng: 'ptBr',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
}

export default i18n
