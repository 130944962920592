import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { Alert, Box, styled } from '@mui/material'
import { useI18n } from 'i18n'
import { FormContainer, TextFieldElement, useWatch } from 'react-hook-form-mui'
import { useMutation } from 'react-query'

import { useSnackbar } from 'components/ui/Snackbar'
import { mutations } from 'features/Auth/mutations'
import { getValidCaptchaToken } from 'features/Captcha/utils'
import { CaptchaWidget } from 'features/Captcha/Widget'
import { events, track } from 'service/track'

type RecoveryPasswordFormFields = {
  email: string
}

const buttonProps: LoadingButtonProps = {
  size: 'large',
  fullWidth: true,
  sx: {
    height: 48,
  },
}

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  form: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    gap: theme.iclinic.spacing.md,
  },
}))

function RecoveryPasswordSuccessAlert() {
  const formValues = useWatch<RecoveryPasswordFormFields>()
  const { t } = useI18n()

  return (
    <Alert severity="info">
      {t('features.auth.recoverPassword.feedback.success', {
        email: formValues.email,
      })}
    </Alert>
  )
}

function RecoveryPasswordFormContent({ isLoading }: { isLoading: boolean }) {
  const formValues = useWatch<RecoveryPasswordFormFields>()
  const { t } = useI18n()

  return (
    <>
      <CaptchaWidget action="request_password_recovery" />

      <TextFieldElement
        type="email"
        fullWidth
        name="email"
        label={t('genericTerms.email')}
        required
      />

      <LoadingButton
        {...buttonProps}
        loading={isLoading}
        disabled={isLoading || !formValues.email}
        variant="contained"
        type="submit"
      >
        {t('features.auth.recoverPassword.submit')}
      </LoadingButton>
    </>
  )
}

export function Form() {
  const { isLoading, mutate, isSuccess } = useMutation(
    mutations.requestPasswordRecoveryEmail,
    {
      onSuccess: () => {
        track(events.requestedPasswordRecover())
      },
      onError: (error: Error) => {
        useSnackbar
          .getState()
          .show({ message: error.message, severity: 'error' })
      },
    }
  )

  const handleSubmit = (fields: RecoveryPasswordFormFields) => {
    mutate({ ...fields, captchaToken: getValidCaptchaToken() })
  }

  return (
    <FormContainer onSuccess={handleSubmit}>
      <Container>
        {isSuccess ? (
          <RecoveryPasswordSuccessAlert />
        ) : (
          <RecoveryPasswordFormContent isLoading={isLoading} />
        )}
      </Container>
    </FormContainer>
  )
}
