import { RegisterParams } from 'features/Auth/types'
import { WithCaptchaToken } from 'features/Captcha/types'
import apiRoutes from 'service/api-routes'

import { protectedApi } from '../service'
import { xCaptchaToken } from '../utils'

const pharmacyApi = {
  register: ({ captchaToken, ...payload }: WithCaptchaToken<RegisterParams>) =>
    protectedApi.post(apiRoutes.register, payload, {
      headers: xCaptchaToken(captchaToken),
    }),
}

export default pharmacyApi
