import { ptBr as form } from 'components/ui/Form/locales'
import { ptBr as auth } from 'features/Auth/locales'
import { ptBr as help } from 'features/Help/locales'
import { ptBr as latestDispensations } from 'features/LatestDispensations/locales'
import { ptBr as rollout } from 'features/Rollout/locales'
import { ptBr as rxDetailsTags } from 'features/RxDetailsTags/locales'
import { ptBr as rxItems } from 'features/RxItems/locales'
import { ptBr as rxPdfPreview } from 'features/RxPdfPreview/locales'
import { ptBr as rxReplaceItem } from 'features/RxReplaceItem/locales'
import { ptBr as rxSearch } from 'features/RxSearch/locales'
import { ptBr as rxSellerPharmacy } from 'features/RxSellerPharmacy/locales'
import { ptBr as rxSignature } from 'features/RxSignature/locales'
import { ptBr as rxDetailsPage } from 'screens/locales'

export const ptBrTransaltion = <const>{
  layout: {
    header: {
      menu: {
        signUp: 'Criar conta',
        signUpLink:
          'https://docs.google.com/forms/d/e/1FAIpQLSd50SAhxpsZQu86gH99ek-J5ICtvFRZHE9767rm2ZYfTMCfnA/viewform',
        signIn: 'Entrar',
        signOut: 'Sair',
        help: 'Como dispensar',
        helpLink:
          'https://suporte.iclinic.com.br/pt-br/como-dispensar-uma-receita-iclinic-rx',
      },
    },
    warningReachability: {
      initialDescription: 'Para ter acesso a receita, verifique com o',
      boldDescription: ' suporte de TI da sua farmácia',
      endDescription:
        ' se os domínios desse site já estão liberados: "*.iclinicrx.com.br" e "*.iclinic.com.br".',
    },
    footer: {
      copyright: '© {{year}} iClinicRx Farmácia  v{{version}}',
      termsOfUse: {
        title: 'Termos de Uso',
        link: 'https://suporte.iclinic.com.br/pt-br/termos-de-uso',
      },
      privatePolicy: {
        title: 'Política de Privacidade',
        link: 'https://suporte.iclinic.com.br/pt-br/politica-de-privacidade-iclinic',
      },
      about: {
        title: 'Sobre iClinic Rx',
        link: 'https://lps.iclinic.com.br/dispensador-de-receitas-iclinicrx/',
      },
    },
  },
  genericTerms: {
    soon: 'em breve',
    appName: 'iClinic Rx Farmácia',
    patient: 'Paciente',
    email: 'E-mail',
  },
  features: {
    auth,
    help,
    latestDispensations,
    rollout,
    rxDetailsTags,
    rxSearch,
    rxItems,
    rxPdfPreview,
    rxSellerPharmacy,
    rxSignature,
    rxReplaceItem,
  },
  form,
  screen: {
    rxDetailsPage,
  },
}
