import { useI18n } from 'i18n'

import { Modal } from 'components/ui/Modal'
import { Login, useAuth } from 'features/Auth'
import { ChangePassword } from 'features/Auth/ChangePassword'
import { RecoverPassword } from 'features/Auth/RecoverPassword'
import { Register, Success } from 'features/Auth/Register'

export function AuthModal() {
  const {
    opened,
    close,
    forcePasswordChange,
    modalVisualization,
    changeVisualization,
  } = useAuth()

  const { t } = useI18n()

  if (!opened) return null

  const Form = {
    login: Login,
    recoverPassword: RecoverPassword,
    changePassword: ChangePassword,
    register: Register,
    registerSuccess: Success,
  }[modalVisualization]

  const title = {
    login: t('features.auth.login.title'),
    changePassword: t('features.auth.changePassword.title'),
    recoverPassword: t('features.auth.recoverPassword.title'),
    register: t('features.auth.register.title'),
    registerSuccess: t('features.auth.register.feedback.success.title'),
  }[modalVisualization]

  const handleClose = () => {
    if (modalVisualization === 'login' ||
       (modalVisualization === 'changePassword' && forcePasswordChange)
    ) {
      close()
    }

    changeVisualization('login')
  }

  return (
    <Modal title={title} onClose={handleClose}>
      <Form />
    </Modal>
  )
}
