import { RefObject, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { Alert, Box, IconButton, InputAdornment, styled } from '@mui/material'
import { useI18n } from 'i18n'
import {
  Control,
  FormContainer,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { useMutation } from 'react-query'
import * as Yup from 'yup'

import { useSnackbar } from 'components/ui/Snackbar'
import { mutations } from 'features/Auth/mutations'
import { getPasswordChangeFormSchema } from 'features/Auth/Register/Form/utils'
import { events, track } from 'service/track'

import { ChangePasswordParams } from '../types'
import { useAuth } from '../useAuth'

const buttonProps: LoadingButtonProps = {
  size: 'large',
  fullWidth: true,
  sx: {
    height: 48,
  },
}

type inputFieldType = 'currentPassword' | 'newPassword' | 'confirmNewPassword'

interface ValidationProps {
  label: string
  isChecked: boolean
}

type InputProps = {
  name: inputFieldType
  label: string
  helperText?: string
  control?: Control<ChangePasswordParams>
  onChange?: () => void
}

type FormFields = Yup.InferType<ReturnType<typeof getPasswordChangeFormSchema>>

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  form: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    gap: theme.iclinic.spacing.md,
  },
}))

const ListConditions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  div: {
    marginBottom: '0.5rem',
  },
})

const ListItemCondition = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.iclinic.color.neutral[500],
  fontSize: theme.iclinic.font.size.xs,
  svg: {
    marginRight: '0.5rem',
    marginTop: '-4px',
  },
}))

export function Form() {
  const { t } = useI18n()
  const { changeVisualization, completePasswordChange } = useAuth()
  const { show } = useSnackbar()

  const formRef: RefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null)

  const {
    control,
    trigger,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<FormFields>({
    criteriaMode: 'all',
    resolver: yupResolver(getPasswordChangeFormSchema()),
  })

  const isNewPasswordValid = (validationMessage: string) => {
    if (errors?.newPassword?.types) {
      const newPasswordErrors = Object.values(errors.newPassword.types).flat()

      return !newPasswordErrors?.includes(validationMessage)
    }

    if (dirtyFields?.newPassword) {
      return true
    }

    return false
  }

  const formFields = useRef<InputProps[]>([
    {
      name: 'currentPassword',
      label: t('features.auth.changePassword.currentPassword'),
    },
    {
      name: 'newPassword',
      label: t('features.auth.changePassword.newPassword'),
      helperText: '',
      onChange: () => trigger('newPassword'),
    },
    {
      name: 'confirmNewPassword',
      label: t('features.auth.changePassword.confirmNewPassword'),
      onChange: () => trigger('confirmNewPassword'),
    },
  ])

  const formValidations = useRef(
    Object.values({
      minLen: {
        label: t('features.auth.changePassword.fieldsConditions.min'),
        validation: t(
          'features.auth.changePassword.validations.minLengthPassword'
        ),
      },
      minLetter: {
        label: t('features.auth.changePassword.fieldsConditions.minLetter'),
        validation: t(
          'features.auth.changePassword.validations.passwordRequirement'
        ),
      },
      minNumber: {
        label: t('features.auth.changePassword.fieldsConditions.minNumber'),
        validation: t(
          'features.auth.changePassword.validations.passwordNumberRequirement'
        ),
      },
    })
  )

  const { isSuccess, mutate: mutateChangePassword } = useMutation(
    mutations.changePassword,
    {
      onSuccess() {
        track(events.changedDefaultPassword())
      },
      onError: (error: Error) => {
        show({
          message: error?.message,
          severity: 'error',
        })
      },
    }
  )

  const submitForm = (payload: FormFields) => {
    mutateChangePassword(payload)
  }

  if (isSuccess) {
    return (
      <Container>
        <Alert severity="success">
          {t('features.auth.changePassword.feedback.success')}
        </Alert>
        <br />
        <LoadingButton
          {...buttonProps}
          variant="contained"
          onClick={() => {
            completePasswordChange()
            changeVisualization('login')
          }}
          type="submit"
        >
          {t('features.auth.changePassword.buttons.success')}
        </LoadingButton>
      </Container>
    )
  }

  return (
    <Container ref={formRef}>
      <Alert data-testid="info-alert" severity="info">
        {t('features.auth.changePassword.info')}
      </Alert>
      <br />
      <FormContainer>
        {formFields.current?.map(({ name, label, helperText, onChange }) => (
          <PasswordInput
            key={label}
            name={name}
            label={label}
            control={control}
            helperText={helperText}
            onChange={onChange}
          />
        ))}

        <ListConditions>
          {formValidations.current?.map(({ label, validation }) => (
            <ValidationsCheck
              key={label}
              label={label}
              isChecked={isNewPasswordValid(validation)}
            />
          ))}
        </ListConditions>

        <LoadingButton
          {...buttonProps}
          onClick={handleSubmit(submitForm)}
          variant="contained"
          type="submit"
        >
          {t('features.auth.changePassword.submit')}
        </LoadingButton>
      </FormContainer>
    </Container>
  )
}

function PasswordInput({
  name,
  label,
  helperText,
  control,
  onChange,
}: InputProps) {
  const [hidePassword, setHidePassword] = useState(true)

  const handlePasswordToggle = () => {
    setHidePassword((prev) => !prev)
    track(events.toggledPasswordVisibility())
  }

  return (
    <TextFieldElement
      fullWidth
      name={name}
      type={hidePassword ? 'password' : 'text'}
      variant="outlined"
      label={label}
      control={control}
      helperText={helperText}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handlePasswordToggle}
              edge="end"
            >
              {hidePassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      required
    />
  )
}

function ValidationsCheck({ label, isChecked }: ValidationProps) {
  return (
    <ListItemCondition key={label} data-testid="validation_check">
      {isChecked ? (
        <CheckCircleIcon
          data-testid="validation_checked"
          fontSize="small"
          color="success"
        />
      ) : (
        <CancelIcon
          data-testid="validation_unchecked"
          fontSize="small"
          color="error"
        />
      )}
      {label}
    </ListItemCondition>
  )
}
