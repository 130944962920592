import { ProductResponse } from 'features/RxReplaceItem/mapper'
import apiRoutes from 'service/api-routes'

import { protectedApi } from '../service'

const productsApi = {
  replacementRx: (id: string) =>
    protectedApi.get<ProductResponse>(apiRoutes.replacementRx(id)),
}

export default productsApi
