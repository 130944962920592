import { apm } from '@elastic/apm-rum'

const isProd = import.meta.env.PROD

const captureError = isProd ? apm.captureError.bind(apm) : console.error

export const logError = (error: string | Error | unknown) => {
  if (typeof error !== 'undefined') {
    captureError(error)
  }
}
