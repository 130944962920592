import { BoxProps } from '@mui/system'
import { useResponsive } from 'hooks/useResponsive'

import { ResponsiveBox } from 'components/ui/ResponsiveBox'

const styleProps: BoxProps<'div'> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flex: 1,
  gap: 4,
  width: '100%',
  maxWidth: 1200,
}

export function TwoColumns(props: BoxProps<'div'>) {
  const isDesktop = useResponsive({ orientation: 'up', size: 'md' })

  return (
    <ResponsiveBox
      {...styleProps}
      {...props}
      sx={{
        ...props.sx,
        ...(isDesktop
          ? {
            mt: 5,
            '> div:nth-of-type(1)': {
              flex: '490px 0 1',
            },
            '> div:nth-of-type(2)': {
              flex: '430px 0 1',
            },
          }
          : {
            mt: 2,
            '> div:nth-of-type(1)': {
              width: '490px',
              maxWidth: '100%',
            },
          }),
      }}
      md={{ flexDirection: 'column' }}
      sm={{ flexDirection: 'column' }}
    />
  )
}
