import { AddressResponse } from 'features/Auth/types'
import apiRoutes from 'service/api-routes'

import { protectedApi } from '../service'

const cepApi = {
  searchAddress(cep: string) {
    return protectedApi.get<AddressResponse>(apiRoutes.cep(cep))
  },
}

export default cepApi
