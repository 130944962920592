import { getSessionId } from 'service/session'
import { TrackingEvents } from 'service/track/events'

import { version } from '../../../package.json'
import { getUser } from '../../features/Auth/useAuth'

const noop = () => {
  // do nothing
}

// https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api
// Installed via Google Tag Manager at `growth-ferramentas@iclinic.com.br`
const clarity: () => (typeof window)['clarity'] = () =>
  'clarity' in window ? window.clarity : noop

// https://developers.google.com/analytics/devguides/collection/ga4/events?hl=pt-br&client_type=gtag
// Installed via Google Tag Manager at `growth-ferramentas@iclinic.com.br`
const gAnalytics: () => (typeof window)['gtag'] = () =>
  'gtag' in window ? window.gtag : noop

const isStagingEnv = import.meta.env.MODE === 'staging'
const isDevEnv = import.meta.env.MODE === 'development'
const isProductionBuild = import.meta.env.PROD
const shouldTrack = isProductionBuild && !isStagingEnv

const getUserReference = () => {
  const user = getUser()

  if (!user || !user.id) return 'anonymous'

  return /@iclinic/.test(user.email) ? user.email : user.id
}

const _logAction = (name: string, props: Record<string, unknown>) => {
  if (isDevEnv || isStagingEnv) {
    // eslint-disable-next-line no-console
    console.group(`Event: "${name}"`)
    console.info(props)
    // eslint-disable-next-line no-console
    console.groupEnd()
  }
}

export const track = (event: TrackingEvents) => {
  const { name, ...props } = event

  const defaultProps = {
    version,
    sessionId: getSessionId() || '',
    user: getUserReference(),
    send_to: 'G-XVNDBT7BMB',
  }

  const opts = { ...defaultProps, ...props }

  if (shouldTrack) gAnalytics()('event', name, opts)
  else _logAction(name, opts)
}

export const identify = () => {
  const sessionId = getSessionId() || ''
  const userReference = getUserReference()

  if (shouldTrack) {
    clarity()('identify', userReference, sessionId)
    clarity()('set', 'version', version)
    clarity()('set', 'user', userReference)
    clarity()('consent')
  } else {
    _logAction('identify', { user: userReference, sessionId })
    _logAction('set', { version })
    _logAction('set', { user: userReference })
    _logAction('consent', {})
  }
}
