import { Breakpoint, useMediaQuery, useTheme } from '@mui/material'

type Options = {
  orientation?: 'up' | 'down'
  size: Breakpoint | number
}

export function useResponsive({ orientation = 'down', size }: Options) {
  const { breakpoints } = useTheme()
  const breakpointOpts = { noSsr: true }

  return useMediaQuery(breakpoints[orientation](size), breakpointOpts)
}
