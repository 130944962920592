export const ptBr = {
  inbound: {
    title: 'Cadastre várias lojas',

    step: {
      makeDownload: 'Faça o download da planilha modelo no link abaixo;',
      fillSheet:
        'Preencha a planilha com as informações das farmácias que deseja cadastrar;',
      sendSheetFor: 'Envie a planilha para',
      waitContact:
        'Em até 2 dias úteis nosso time entrará em contato confirmando o cadastro e explicando como ativar as contas para dispensação.',
    },

    action: {
      cta: 'Baixar planilha modelo',
    },

    feedback: {
      downloadError:
        'Não foi possível baixar a planilha no momento. Tente novamente!',
    },
  },
}
