import { onlyLetNumbers } from './string'

export const validateCnpjValue = (cnpj: string): boolean => {
  const cnpjRegex = /^\d{14}$/

  cnpj = onlyLetNumbers(cnpj)

  if (!cnpjRegex.test(cnpj)) {
    return false
  }

  const cleanCnpj = cnpj.replace(/[^\d]/g, '')

  let sum = 0
  let multiplier = 2
  let digitVerifier1 = 0
  let digitVerifier2 = 0

  for (let i = 11; i >= 0; i--) {
    if (i < 4) {
      sum += parseInt(cleanCnpj.charAt(i), 10) * multiplier
      multiplier++
    } else {
      sum += parseInt(cleanCnpj.charAt(i), 10) * multiplier
      multiplier = multiplier === 9 ? 2 : multiplier + 1
    }
  }

  const remainder = sum % 11

  digitVerifier1 = remainder < 2 ? 0 : 11 - remainder

  sum = 0
  multiplier = 2

  for (let i = 12; i >= 0; i--) {
    if (i < 5) {
      sum += parseInt(cleanCnpj.charAt(i), 10) * multiplier
      multiplier++
    } else {
      sum += parseInt(cleanCnpj.charAt(i), 10) * multiplier
      multiplier = multiplier === 9 ? 2 : multiplier + 1
    }
  }

  const remainder2 = sum % 11

  digitVerifier2 = remainder2 < 2 ? 0 : 11 - remainder2

  if (
    parseInt(cleanCnpj.charAt(12), 10) !== digitVerifier1 ||
    parseInt(cleanCnpj.charAt(13), 10) !== digitVerifier2
  ) {
    return false
  }

  return true
}
