
import Turnstile from 'react-turnstile'

import { CaptchaWidgetProps } from '../types'

const CAPTCHA_SITE_KEY = import.meta.env.VITE_CAPTCHA_SITE_KEY ?? ''

export function CaptchaWidget(props: CaptchaWidgetProps) {
  return (
    <Turnstile
      id="captcha-widget"
      refreshExpired="auto"
      retryInterval={300}
      sitekey={CAPTCHA_SITE_KEY}
      {...props}
    />
  )
}
