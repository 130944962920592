import { translate } from 'i18n'
import * as Yup from 'yup'

import { validateCnpjValue } from 'utils/validations'

const regexCep = /^\d{5}-?\d{3}$/

export const getZipCodeSchema = () =>
  Yup.string()
    .matches(
      regexCep,
      translate('features.auth.register.validations.cepInvalid')
    )
    .required(translate('features.auth.register.validations.fieldRequired'))

export const getCnpjSchema = () =>
  Yup.string()
    .required(translate('features.auth.register.validations.cnpjInvalid'))
    .test(
      'cnpj',
      translate('features.auth.register.validations.cnpjInvalid'),
      (value) => validateCnpjValue(value)
    )

export const getFormSchema = () =>
  Yup.object().shape({
    companyName: Yup.string().required(
      translate('features.auth.register.validations.fieldRequired')
    ),
    cnpj: Yup.string().required(
      translate('features.auth.register.validations.fieldRequired')
    ),
    phone: Yup.string().required(
      translate('features.auth.register.validations.fieldRequired')
    ),
    email: Yup.string().required(
      translate('features.auth.register.validations.fieldRequired')
    ),
    confirmEmail: Yup.string()
      .required(translate('features.auth.register.validations.fieldRequired'))
      .oneOf(
        [Yup.ref('email')],
        translate('features.auth.register.validations.emailDontMatch')
      ),
    address: Yup.object({
      zipCode: getZipCodeSchema(),
      street: Yup.string().required(
        translate('features.auth.register.validations.fieldRequired')
      ),
      number: Yup.string().required(
        translate('features.auth.register.validations.fieldRequired')
      ),
      neighborhood: Yup.string().required(
        translate('features.auth.register.validations.fieldRequired')
      ),
      city: Yup.string().required(
        translate('features.auth.register.validations.fieldRequired')
      ),
      state: Yup.string().required(
        translate('features.auth.register.validations.fieldRequired')
      ),
      complement: Yup.string(),
    }),
    password: Yup.string()
      .required(translate('features.auth.register.validations.fieldRequired'))
      .min(
        8,
        translate('features.auth.register.validations.minLengthPassword')
      ),
    confirmPassword: Yup.string()
      .required(translate('features.auth.register.validations.fieldRequired'))
      .min(8, translate('features.auth.register.validations.minLengthPassword'))
      .oneOf(
        [Yup.ref('password')],
        translate('features.auth.register.validations.passwordDontMatch')
      ),
  })

export const getPasswordChangeFormSchema = () =>
  Yup.object().shape({
    currentPassword: Yup.string().required(
      translate('features.auth.changePassword.validations.fieldRequired')
    ),
    newPassword: Yup.string()
      .required(
        translate('features.auth.changePassword.validations.fieldRequired')
      )
      .matches(
        /[a-zA-Z]/,
        translate(
          'features.auth.changePassword.validations.passwordRequirement'
        )
      )
      .matches(
        /\d/,
        translate(
          'features.auth.changePassword.validations.passwordNumberRequirement'
        )
      )
      .min(
        8,
        translate('features.auth.changePassword.validations.minLengthPassword')
      ),
    confirmNewPassword: Yup.string()
      .required(
        translate('features.auth.changePassword.validations.fieldRequired')
      )
      .min(
        8,
        translate('features.auth.changePassword.validations.minLengthPassword')
      )
      .oneOf(
        [Yup.ref('newPassword')],
        translate('features.auth.changePassword.validations.passwordDontMatch')
      ),
  })
