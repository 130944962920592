import { useEffect } from 'react'

let didInit = false

/**
 * Hook to fix react development mode problem
 * with useEffect on the root component
 * https://beta.reactjs.org/learn/you-might-not-need-an-effect#initializing-the-application
 * @param onInitFn Function to be called only
 * once, when the react app is initialized
 */
export function useOnReactAppInit(onInitFn: () => void) {
  useEffect(() => {
    if (didInit) return

    onInitFn()
    didInit = true
  }, [onInitFn])
}
